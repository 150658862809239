import React from "react";
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import {BEVS,SERVE,DRINK} from '../constants';




  
function Footer() {
    return (
      
      <>
        <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
          <Link color="inherit" href="https://material-ui.com/">
            Your Website
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>

        <Typography variant="body2" color="textSecondary" align="center">
        {'Random Text'}{<br></br>}
        <Link color="inherit" href="https://material-ui.com/">
          Take Some Drink With You
        </Link>{<br></br>}
        {DRINK}{<br></br>}{BEVS}{<br></br>}{SERVE}{<br></br>}
      </Typography>

      
      
      </>

      
    );



   
  }


  export default Footer;
