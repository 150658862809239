import React from 'react';
 

import { NavLink } from 'react-router-dom';

const Navigation = () => {
    return (
     <div>
{/*        
        <NavLink to="/">Home</NavLink>
          <NavLink to="/pricing">Pricing</NavLink>
          <NavLink to="/signup">Signup</NavLink>
          <NavLink to="/contact">Contact</NavLink>  */}

       </div>
       



    );
}
 
export default Navigation;