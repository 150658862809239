import React from 'react';
import "../App.css";
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import {CDN,LOGO,ABOUT_HEAD,ABOUT_BODY,PMCID,ENDSYSTEM,HSAPIV} from './constants';
import Footer from "./functions/Footer";
import { Stocks } from "./Stocks";

var axios = require('axios');
var data = JSON.stringify([
  "434",
  "454",
  "442",
  "443",
  "446"
]);

var config = {
  method: 'post',
  url: 'https://hsapi.escapia.com/dragomanadapter/hsapi/GetUnitsById',
  headers: { 
    'X-Homeaway-Hasp-Api-Version': {HSAPIV}, 
    'X-Homeaway-Hasp-Api-Endsystem': {ENDSYSTEM}, 
    'X-homeaway-Hasp-Api-Pmcid': {PMCID}, 
    'Authorization': 'Bearer MzgzNDQwOTUtZWE0Yy00MTFhLThjMzEtZDYwNDRiYTJlNTM0', 
    'Content-Type': 'application/json', 
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
  console.log(response.data);
  const testJson = console.log((response.data));

  return(
    <>
    </>
)
  this.setState({array:response})
})
.catch(function (error) {
  console.log(error);
});



const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));




const footers = [
  {
    title: 'Company',
    description: ['Team', 'History', 'Contact us', 'Locations'],
  },
  {
    title: 'Features',
    description: ['Cool stuff', 'Random feature', 'Team feature', 'Developer stuff', 'Another one'],
  },
  {
    title: 'Resources',
    description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
  },
  {
    title: 'Legal',
    description: ['Privacy policy', 'Terms of use','Other Stuff'],
  },

];


export default function About(props) 
{
  const getUnits = (props) =>

  {
if (getUnits.length < 4)
{
  return (<h3>There are 5</h3>);

}
else
{

  return (<h3>More Than 4</h3>);
}
};

const classes = useStyles();


  return (
    <>
      <CssBaseline />
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
          <img src={LOGO} ></img>
          </Typography>
          <nav>
            <Link variant="button" color="textPrimary" href="features" className={classes.link}>
              Features
            </Link>
            <Link variant="button" color="textPrimary" href="pricing" className={classes.link}>
              Pricing
            </Link>
            <Link variant="button" color="textPrimary" href="support" className={classes.link}>
              Support
            </Link> 
            <Link variant="button" color="textPrimary" href="about" className={classes.link}>
              About
            </Link>
          </nav>
          <Button href="#" color="primary" variant="outlined" className={classes.link}>
            Login
          </Button>
        </Toolbar>
      </AppBar>
      {/* Hero unit */}
    <img src={CDN} width="100%"></img>
      <Container maxWidth="md" component="main" className={classes.heroContent}>
      
        <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
       {ABOUT_HEAD}
        </Typography>
        <Typography align="center" color="textSecondary" component="p">

        {ABOUT_BODY}

        <Stocks />
      

        {getUnits(props)}
        
        </Typography>
      </Container>
      {/* End hero unit */}


      {/* Footer */}
      <Container maxWidth="lg" align="center" component="footer" className={classes.footer}>
        <Grid container spacing={4} justify="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item) => (
                  <li key={item}>
                    <Link href="stuff" variant="subtitle1" color="textSecondary">
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Box mt={15}>
        <Footer />

        


        <testJson />
        </Box>
      </Container>
      {/* End footer */}
    </>
  );
}