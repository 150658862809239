//TEXT
export const BEVS = "Beverages"
export const SERVE = "Serves"
export const DRINK = "Drinks Are On Me"

//HSAPI
export const HSAPIV = "'10'"
export const PMCID = "'0865'"
export const ENDSYSTEM = "'V12'"

//IMAGES
export const CDN = "https://cms.seascape-resort.com/Images/WhalesTail2021-7.jpg"
export const LOGO = "https://cms.seascape-resort.com/Images/SeascapeMainLogo.png"

//CORE PAGES

export const ABOUT_HEAD = "Looking For The Best Destin, Florida Resorts? Look No Further!"
export const ABOUT_BODY = "Seascape Resort has been known as one of the absolute best Destin Florida resorts for more than 4 decades! With time, Seascape Resort has grown to encompass the luxuries of today without losing the comfortable, relaxing atmosphere that is the foundation of the Destin, Florida area. Known as Destin's best kept secret, Seascape Resort is encompasses over 300 acres of lush greens, serene lakes and 2000 feet of pristine white sand beach. We are one of the best resorts in Destin, Florida! We are a kid and family friendly with plenty of on-site activities for children of all ages."


