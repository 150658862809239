import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
 
import Home from './components/Home';
import Pricing from './components/Pricing';
import Signup from './components/Signup';
import SignIn from './components/SignIn';
import About from './components/About';
import Error from './components/Error';
import Navigation from './components/Navigation';
import Features from './components/Features';
import Support from './components/Support';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';


function App() {
  
  return (
<>
<BrowserRouter>
        <div>
          <Navigation />
            <Switch>
             <Route path="/" component={Home} exact/>
             <Route path="/Pricing" component={Pricing}/>
             <Route path="/About" component={About}/>
             <Route path="/Signup" component={Signup}/>
             <Route path="/SignIn" component={SignIn}/>
             <Route path="/Features" component={Features}/>
             <Route path="/Support" component={Support}/>
            <Route component={Error}/>
           </Switch>
        </div> 
      </BrowserRouter>
  </>

  );
  
}



export default App;

