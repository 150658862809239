export const stockData = [
    {
      company: "Twitter Inc",
      ticker: "TWTR",
      stockPrice: "22.76 USD",
      timeElapsed: "5 sec ago",
    },
    {
      company: "Square Inc",
      ticker: "SQ",
      stockPrice: "45.28 USD",
      timeElapsed: "10 sec ago",
    },
    {
      company: "Shopify Inc",
      ticker: "SHOP",
      stockPrice: "341.79 USD",
      timeElapsed: "3 sec ago",
    },
    {
      company: "Sunrun Inc",
      ticker: "RUN",
      stockPrice: "9.87 USD",
      timeElapsed: "4 sec ago",
    },
    {
      company: "Adobe Inc",
      ticker: "ADBE",
      stockPrice: "300.99 USD",
      timeElapsed: "10 sec ago",
    },
    {
      company: "HubSpot Inc",
      ticker: "HUBS",
      stockPrice: "115.22 USD",
      timeElapsed: "12 sec ago",
    },
  ];


  export const getUnits = [
    {
        "$id": "1",
        "unitName": "Ariel Dunes 0405",
        "unitCode": "AD0405",
        "unitComplex": "ADI",
        "unitHeadline": "Hall bunks and great pool view.",
        "address": {
            "$id": "2",
            "street1": "112 Seascape Blvd",
            "street2": "",
            "city": "Miramar Beach",
            "state": "FL",
            "province": null,
            "zip": "32550",
            "country": null,
            "addressContactTypeId": null,
            "isPrimary": false,
            "id": 0,
            "pmcid": null,
            "nativePMSID": null
        },
        "headline": "Ariel Dunes 0405",
        "latitude": 30.377503,
        "longitude": -86.36677,
        "bedrooms": 1.0,
        "bathrooms": 1.5,
        "bedGroups": [
            {
                "$id": "3",
                "bedType": {
                    "$id": "4",
                    "name": "OtherBeds",
                    "id": 0,
                    "pmcid": null,
                    "nativePMSID": null
                },
                "bedQuantity": 1
            }
        ],
        "sleeps": 6.0,
        "internalDescription": "MMP-1",
        "webDescription": "The One bedroom/One and half Bathroom Sandpiper Units offer 900 square feet of space to sprawl out during your Destin vacation. These layouts offer plenty of privacy for those who are lucky enough to grab the enclosed bedroom; while still providing convenience for guests who choose the hall bunks to rejuvenate each night. \r\n<br>\r\n<br>\r\nEach Destin condo rental in Ariel Dunes has 9 foot ceilings, and an 8 foot sliding glass door to help bring the gorgeous gulf view to life! Granite counter-tops, carpeting, tile and GE profile appliances are just some of the details to ensure that your vacation is truly luxurious. <br>\r\n<br>\r\nPrivately owned, each Ariel Dunes Destin Condo has a unique atmosphere and personal charm communicated by the choices of each owner in their furnishings and decorations. Please take a moment to view all of the photos to truly imagine your Destin Vacation experience with Seascape at the Ariel Dunes condominiums.&quot;",
        "shortDescription": null,
        "guestGuidance": null,
        "featureGroups": [
            {
                "$id": "5",
                "name": "Type",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "6",
                        "name": "Condominium",
                        "displayOrder": 0,
                        "group": {
                            "$id": "7",
                            "name": "Type",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "45"
                        },
                        "value": "Co",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "440"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "45"
            },
            {
                "$id": "8",
                "name": "Bedrooms",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": true,
                "features": [
                    {
                        "$id": "9",
                        "name": "1 Bedroom",
                        "displayOrder": 0,
                        "group": {
                            "$id": "10",
                            "name": "Bedrooms",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": true,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "1"
                        },
                        "value": "1B",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "1"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "1"
            },
            {
                "$id": "11",
                "name": "Bathrooms",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": true,
                "features": [
                    {
                        "$id": "12",
                        "name": "1.5 Bathrooms",
                        "displayOrder": 0,
                        "group": {
                            "$id": "13",
                            "name": "Bathrooms",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": true,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "2"
                        },
                        "value": "1+",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "11"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "2"
            },
            {
                "$id": "14",
                "name": "Occupancy",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": true,
                "features": [
                    {
                        "$id": "15",
                        "name": "6",
                        "displayOrder": 0,
                        "group": {
                            "$id": "16",
                            "name": "Occupancy",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": true,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "3"
                        },
                        "value": "06",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "23"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "3"
            },
            {
                "$id": "17",
                "name": "Complex",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "18",
                        "name": "ADI",
                        "displayOrder": 0,
                        "group": {
                            "$id": "19",
                            "name": "Complex",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "5"
                        },
                        "value": "A1",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "237"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "5"
            },
            {
                "$id": "20",
                "name": "View",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "21",
                        "name": "Gulf View",
                        "displayOrder": 0,
                        "group": {
                            "$id": "22",
                            "name": "View",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "6"
                        },
                        "value": "GV",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "252"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "6"
            },
            {
                "$id": "23",
                "name": "Bedding 1",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "24",
                        "name": "King",
                        "displayOrder": 0,
                        "group": {
                            "$id": "25",
                            "name": "Bedding 1",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "15"
                        },
                        "value": "KN",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "57"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "15"
            },
            {
                "$id": "26",
                "name": "Bedding 2",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "27",
                        "name": "Bunks",
                        "displayOrder": 0,
                        "group": {
                            "$id": "28",
                            "name": "Bedding 2",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "16"
                        },
                        "value": "BK",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "76"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "16"
            },
            {
                "$id": "29",
                "name": "Bedding 3",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "30",
                        "name": "Sofa Sleeper",
                        "displayOrder": 0,
                        "group": {
                            "$id": "31",
                            "name": "Bedding 3",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "17"
                        },
                        "value": "SS",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "89"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "17"
            },
            {
                "$id": "32",
                "name": "Pets Allowed",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "33",
                        "name": "Unavailable for pets",
                        "displayOrder": 0,
                        "group": {
                            "$id": "34",
                            "name": "Pets Allowed",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "13"
                        },
                        "value": "no",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "52"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "13"
            },
            {
                "$id": "35",
                "name": "Laundry",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "36",
                        "name": "Yes - In Unit",
                        "displayOrder": 0,
                        "group": {
                            "$id": "37",
                            "name": "Laundry",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "31"
                        },
                        "value": "YS",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "191"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "31"
            },
            {
                "$id": "38",
                "name": "Exercise Room",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "39",
                        "name": "On Property",
                        "displayOrder": 0,
                        "group": {
                            "$id": "40",
                            "name": "Exercise Room",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "40"
                        },
                        "value": "op",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "216"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "40"
            },
            {
                "$id": "41",
                "name": "Wifi",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "42",
                        "name": "Free Wifi",
                        "displayOrder": 0,
                        "group": {
                            "$id": "43",
                            "name": "Wifi",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "47"
                        },
                        "value": "FW",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "262"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "47"
            },
            {
                "$id": "44",
                "name": "Smoking",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "45",
                        "name": "Outdoor Smoking Only",
                        "displayOrder": 0,
                        "group": {
                            "$id": "46",
                            "name": "Smoking",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "12"
                        },
                        "value": "ys",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "50"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "12"
            },
            {
                "$id": "47",
                "name": "Parking",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "48",
                        "name": "Parking Lot",
                        "displayOrder": 0,
                        "group": {
                            "$id": "49",
                            "name": "Parking",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "33"
                        },
                        "value": "PL",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "259"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "33"
            },
            {
                "$id": "50",
                "name": "Parking Spaces",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "51",
                        "name": "2",
                        "displayOrder": 0,
                        "group": {
                            "$id": "52",
                            "name": "Parking Spaces",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "34"
                        },
                        "value": "02",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "199"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "34"
            },
            {
                "$id": "53",
                "name": "HDTV",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "54",
                        "name": "HDTV",
                        "displayOrder": 0,
                        "group": {
                            "$id": "55",
                            "name": "HDTV",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "43"
                        },
                        "value": "HD",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "222"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "43"
            },
            {
                "$id": "56",
                "name": "Dishwasher",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "57",
                        "name": "yes",
                        "displayOrder": 0,
                        "group": {
                            "$id": "58",
                            "name": "Dishwasher",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "32"
                        },
                        "value": "YS",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "194"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "32"
            },
            {
                "$id": "59",
                "name": "Air Conditioning",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "60",
                        "name": "yes",
                        "displayOrder": 0,
                        "group": {
                            "$id": "61",
                            "name": "Air Conditioning",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "30"
                        },
                        "value": "YS",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "190"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "30"
            },
            {
                "$id": "62",
                "name": "Pool",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "63",
                        "name": "Community",
                        "displayOrder": 0,
                        "group": {
                            "$id": "64",
                            "name": "Pool",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "8"
                        },
                        "value": "CM",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "41"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "8"
            },
            {
                "$id": "65",
                "name": "Hot Tub",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "66",
                        "name": "None",
                        "displayOrder": 0,
                        "group": {
                            "$id": "67",
                            "name": "Hot Tub",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "9"
                        },
                        "value": "no",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "42"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "9"
            },
            {
                "$id": "68",
                "name": "Beach Service",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "69",
                        "name": "Seasonal Beach Srvc",
                        "displayOrder": 0,
                        "group": {
                            "$id": "70",
                            "name": "Beach Service",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "48"
                        },
                        "value": "YS",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "265"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "48"
            },
            {
                "$id": "71",
                "name": "Water Activities",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "72",
                        "name": "On Resort",
                        "displayOrder": 0,
                        "group": {
                            "$id": "73",
                            "name": "Water Activities",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "53"
                        },
                        "value": "WA",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "275"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "53"
            },
            {
                "$id": "74",
                "name": "Golf",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "75",
                        "name": "On Resort",
                        "displayOrder": 0,
                        "group": {
                            "$id": "76",
                            "name": "Golf",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "28"
                        },
                        "value": "NB",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "184"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "28"
            },
            {
                "$id": "77",
                "name": "Tennis",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "78",
                        "name": "On Resort",
                        "displayOrder": 0,
                        "group": {
                            "$id": "79",
                            "name": "Tennis",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "29"
                        },
                        "value": "ON",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "188"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "29"
            },
            {
                "$id": "80",
                "name": "Conference Center",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "81",
                        "name": "On Resort",
                        "displayOrder": 0,
                        "group": {
                            "$id": "82",
                            "name": "Conference Center",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "50"
                        },
                        "value": "YS",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "269"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "50"
            },
            {
                "$id": "83",
                "name": "Restaurants",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "84",
                        "name": "On-site Restaurants",
                        "displayOrder": 0,
                        "group": {
                            "$id": "85",
                            "name": "Restaurants",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "49"
                        },
                        "value": "YS",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "267"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "49"
            },
            {
                "$id": "86",
                "name": "Tram Service",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "87",
                        "name": "Seasonal Tram Srvc",
                        "displayOrder": 0,
                        "group": {
                            "$id": "88",
                            "name": "Tram Service",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "51"
                        },
                        "value": "TR",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "271"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "51"
            },
            {
                "$id": "89",
                "name": "Bike Rentals",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "90",
                        "name": "On Resort",
                        "displayOrder": 0,
                        "group": {
                            "$id": "91",
                            "name": "Bike Rentals",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "52"
                        },
                        "value": "BR",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "273"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "52"
            },
            {
                "$id": "92",
                "name": "Grill/BBQ",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "93",
                        "name": "Community Grill",
                        "displayOrder": 0,
                        "group": {
                            "$id": "94",
                            "name": "Grill/BBQ",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "38"
                        },
                        "value": "OP",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "211"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "38"
            },
            {
                "$id": "95",
                "name": "Elevator",
                "displayOrder": 0,
                "mutuallyExclusiveFeatures": false,
                "allowOrMore": false,
                "features": [
                    {
                        "$id": "96",
                        "name": "yes",
                        "displayOrder": 0,
                        "group": {
                            "$id": "97",
                            "name": "Elevator",
                            "displayOrder": 0,
                            "mutuallyExclusiveFeatures": false,
                            "allowOrMore": false,
                            "features": [],
                            "id": 0,
                            "pmcid": "0865",
                            "nativePMSID": "35"
                        },
                        "value": "YS",
                        "orMore": false,
                        "type": "String",
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "203"
                    }
                ],
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "35"
            }
        ],
        "unitType": {
            "$id": "98",
            "name": "A11BGV",
            "id": 0,
            "pmcid": null,
            "nativePMSID": "42"
        },
        "thumbnailImageURL": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (4 of 9).jpg?width=120&height=90&mode=max",
        "available": false,
        "notes": "REVENUE GUARANTEE - John & Susan Gannon / Home -931-552-4961 / His Cell 931-801-6571\r\nemail - johnmgannonsr@gmail.com",
        "keycode": "MMP-1 RG",
        "kabaDoorName": "AD0405",
        "detailURL": "https://www.seascape-resort.com/vacation-rentals/AD0405",
        "owners": [
            {
                "$id": "99",
                "firstName": "Inc, Seascape Resort,",
                "lastName": "",
                "phones": [],
                "emails": [
                    {
                        "$id": "100",
                        "address": "sharon@seascape-resort.com",
                        "emailContactTypeId": "3",
                        "isPrimary": true,
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "33944"
                    },
                    {
                        "$id": "101",
                        "address": "corey@seascape-resort.com",
                        "emailContactTypeId": "1",
                        "isPrimary": false,
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "40299"
                    },
                    {
                        "$id": "102",
                        "address": "harichopra@hotmail.com",
                        "emailContactTypeId": "1",
                        "isPrimary": false,
                        "id": 0,
                        "pmcid": "0865",
                        "nativePMSID": "42381"
                    }
                ],
                "addresses": [
                    {
                        "$id": "103",
                        "street1": "",
                        "street2": "",
                        "city": "Miramar Beach",
                        "state": "FL",
                        "province": null,
                        "zip": "32550",
                        "country": "",
                        "addressContactTypeId": "0",
                        "isPrimary": false,
                        "id": 0,
                        "pmcid": null,
                        "nativePMSID": "0"
                    }
                ],
                "ownsUnitNativePMSIDs": [
                    "713",
                    "434",
                    "666",
                    "437",
                    "674",
                    "442",
                    "711",
                    "477",
                    "486",
                    "489",
                    "761",
                    "495",
                    "501",
                    "757",
                    "505",
                    "682",
                    "515",
                    "527",
                    "529",
                    "530",
                    "532",
                    "680",
                    "551",
                    "598",
                    "760",
                    "602",
                    "604",
                    "607",
                    "687",
                    "613",
                    "615",
                    "749",
                    "750",
                    "753",
                    "621",
                    "712",
                    "628",
                    "636",
                    "741"
                ],
                "ownedUnits": [
                    {
                        "$id": "104",
                        "nativePMSID": "713",
                        "name": "Ariel Dunes 0404",
                        "code": "AD0404",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "105",
                        "nativePMSID": "434",
                        "name": "Ariel Dunes 0405",
                        "code": "AD0405",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "106",
                        "nativePMSID": "666",
                        "name": "Ariel Dunes 0505",
                        "code": "AD0505",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "107",
                        "nativePMSID": "437",
                        "name": "Ariel Dunes 0606",
                        "code": "AD0606",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "108",
                        "nativePMSID": "674",
                        "name": "Ariel Dunes 0708",
                        "code": "AD0708",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "109",
                        "nativePMSID": "442",
                        "name": "Ariel Dunes 0806",
                        "code": "AD0806",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "110",
                        "nativePMSID": "711",
                        "name": "Ariel Dunes 1906",
                        "code": "AD1906",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "111",
                        "nativePMSID": "477",
                        "name": "Ariel Dunes 2205",
                        "code": "AD2205",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "112",
                        "nativePMSID": "486",
                        "name": "Ariel Dunes II 0207",
                        "code": "ADII0207",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "113",
                        "nativePMSID": "489",
                        "name": "Ariel Dunes II 0306",
                        "code": "ADII0306",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "114",
                        "nativePMSID": "761",
                        "name": "Ariel Dunes II 0507",
                        "code": "ADII0507",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "115",
                        "nativePMSID": "495",
                        "name": "Ariel Dunes II 0605",
                        "code": "ADII0605",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "116",
                        "nativePMSID": "501",
                        "name": "Ariel Dunes II 1006",
                        "code": "ADII1006",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "117",
                        "nativePMSID": "757",
                        "name": "Ariel Dunes II 1106",
                        "code": "ADII1106",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "118",
                        "nativePMSID": "505",
                        "name": "Ariel Dunes II 1205",
                        "code": "ADII1205",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "119",
                        "nativePMSID": "682",
                        "name": "Ariel Dunes II 1506",
                        "code": "ADII1506",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "120",
                        "nativePMSID": "515",
                        "name": "Ariel Dunes II 1510",
                        "code": "ADII1510",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "121",
                        "nativePMSID": "527",
                        "name": "Ariel Dunes II 2107",
                        "code": "ADII2107",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "122",
                        "nativePMSID": "529",
                        "name": "Ariel Dunes II 2202",
                        "code": "ADII2202",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "123",
                        "nativePMSID": "530",
                        "name": "Ariel Dunes II 2203",
                        "code": "ADII2203",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "124",
                        "nativePMSID": "532",
                        "name": "Ariel Dunes II 2305",
                        "code": "ADII2305",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "125",
                        "nativePMSID": "680",
                        "name": "Ariel Dunes II 2404",
                        "code": "ADII2404",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "126",
                        "nativePMSID": "551",
                        "name": "Golf Course 06C",
                        "code": "GC06C",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "127",
                        "nativePMSID": "598",
                        "name": "Majestic Sun 0207B",
                        "code": "MS0207B",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "128",
                        "nativePMSID": "760",
                        "name": "Majestic Sun 0303B",
                        "code": "MS0303B",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "129",
                        "nativePMSID": "602",
                        "name": "Majestic Sun 0306B",
                        "code": "MS0306B",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "130",
                        "nativePMSID": "604",
                        "name": "Majestic Sun 0312B",
                        "code": "MS0312B",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "131",
                        "nativePMSID": "607",
                        "name": "Majestic Sun 0501A",
                        "code": "MS0501A",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "132",
                        "nativePMSID": "687",
                        "name": "Majestic Sun 0503A",
                        "code": "MS0503A",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "133",
                        "nativePMSID": "613",
                        "name": "Majestic Sun 0606B",
                        "code": "MS0606B",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "134",
                        "nativePMSID": "615",
                        "name": "Majestic Sun 0609A",
                        "code": "MS0609A",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "135",
                        "nativePMSID": "749",
                        "name": "Majestic Sun 0702B",
                        "code": "MS0702B",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "136",
                        "nativePMSID": "750",
                        "name": "Majestic Sun 0713A",
                        "code": "MS0713A",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "137",
                        "nativePMSID": "753",
                        "name": "Majestic Sun 0805B",
                        "code": "MS0805B",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "138",
                        "nativePMSID": "621",
                        "name": "Majestic Sun 0806B",
                        "code": "MS0806B",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "139",
                        "nativePMSID": "712",
                        "name": "Majestic Sun 0904B",
                        "code": "MS0904B",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "140",
                        "nativePMSID": "628",
                        "name": "Majestic Sun 1003B",
                        "code": "MS1003B",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "141",
                        "nativePMSID": "636",
                        "name": "556 Seascape Dr",
                        "code": "SRICLEAR",
                        "isActive": true,
                        "share": 0.0
                    },
                    {
                        "$id": "142",
                        "nativePMSID": "741",
                        "name": "Tennis Villa 87B",
                        "code": "TV87B",
                        "isActive": true,
                        "share": 0.0
                    }
                ],
                "notes": "",
                "id": 0,
                "pmcid": "0865",
                "nativePMSID": "498"
            }
        ],
        "scaledImages": [
            {
                "$id": "143",
                "caption": "Living Room",
                "displayOrder": 1,
                "options": [
                    {
                        "$id": "144",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (4 of 9).jpg",
                        "scale": "Original",
                        "height": 535,
                        "width": 800
                    },
                    {
                        "$id": "145",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (4 of 9).jpg",
                        "scale": "Detail",
                        "height": 535,
                        "width": 800
                    },
                    {
                        "$id": "146",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (4 of 9).jpg?width=120&height=90&mode=max",
                        "scale": "SmallThumbnail",
                        "height": 90,
                        "width": 120
                    },
                    {
                        "$id": "147",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (4 of 9).jpg?width=360&height=270&mode=max",
                        "scale": "LargeThumbnail",
                        "height": 270,
                        "width": 360
                    }
                ],
                "id": 0,
                "pmcid": null,
                "nativePMSID": "82"
            },
            {
                "$id": "148",
                "caption": "Kitchen",
                "displayOrder": 2,
                "options": [
                    {
                        "$id": "149",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (2 of 9).jpg",
                        "scale": "Original",
                        "height": 535,
                        "width": 800
                    },
                    {
                        "$id": "150",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (2 of 9).jpg",
                        "scale": "Detail",
                        "height": 535,
                        "width": 800
                    },
                    {
                        "$id": "151",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (2 of 9).jpg?width=120&height=90&mode=max",
                        "scale": "SmallThumbnail",
                        "height": 90,
                        "width": 120
                    },
                    {
                        "$id": "152",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (2 of 9).jpg?width=360&height=270&mode=max",
                        "scale": "LargeThumbnail",
                        "height": 270,
                        "width": 360
                    }
                ],
                "id": 0,
                "pmcid": null,
                "nativePMSID": "81"
            },
            {
                "$id": "153",
                "caption": "Master Bedroom",
                "displayOrder": 3,
                "options": [
                    {
                        "$id": "154",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (1 of 9).jpg",
                        "scale": "Original",
                        "height": 535,
                        "width": 800
                    },
                    {
                        "$id": "155",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (1 of 9).jpg",
                        "scale": "Detail",
                        "height": 535,
                        "width": 800
                    },
                    {
                        "$id": "156",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (1 of 9).jpg?width=120&height=90&mode=max",
                        "scale": "SmallThumbnail",
                        "height": 90,
                        "width": 120
                    },
                    {
                        "$id": "157",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (1 of 9).jpg?width=360&height=270&mode=max",
                        "scale": "LargeThumbnail",
                        "height": 270,
                        "width": 360
                    }
                ],
                "id": 0,
                "pmcid": null,
                "nativePMSID": "78"
            },
            {
                "$id": "158",
                "caption": "Bunk Cubbies",
                "displayOrder": 4,
                "options": [
                    {
                        "$id": "159",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (6 of 9).jpg",
                        "scale": "Original",
                        "height": 535,
                        "width": 800
                    },
                    {
                        "$id": "160",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (6 of 9).jpg",
                        "scale": "Detail",
                        "height": 535,
                        "width": 800
                    },
                    {
                        "$id": "161",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (6 of 9).jpg?width=120&height=90&mode=max",
                        "scale": "SmallThumbnail",
                        "height": 90,
                        "width": 120
                    },
                    {
                        "$id": "162",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (6 of 9).jpg?width=360&height=270&mode=max",
                        "scale": "LargeThumbnail",
                        "height": 270,
                        "width": 360
                    }
                ],
                "id": 0,
                "pmcid": null,
                "nativePMSID": "79"
            },
            {
                "$id": "163",
                "caption": "Balcony",
                "displayOrder": 5,
                "options": [
                    {
                        "$id": "164",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (9 of 9).jpg",
                        "scale": "Original",
                        "height": 535,
                        "width": 800
                    },
                    {
                        "$id": "165",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (9 of 9).jpg",
                        "scale": "Detail",
                        "height": 535,
                        "width": 800
                    },
                    {
                        "$id": "166",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (9 of 9).jpg?width=120&height=90&mode=max",
                        "scale": "SmallThumbnail",
                        "height": 90,
                        "width": 120
                    },
                    {
                        "$id": "167",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (9 of 9).jpg?width=360&height=270&mode=max",
                        "scale": "LargeThumbnail",
                        "height": 270,
                        "width": 360
                    }
                ],
                "id": 0,
                "pmcid": null,
                "nativePMSID": "80"
            },
            {
                "$id": "168",
                "caption": "Pool View",
                "displayOrder": 6,
                "options": [
                    {
                        "$id": "169",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (8 of 9).jpg",
                        "scale": "Original",
                        "height": 535,
                        "width": 800
                    },
                    {
                        "$id": "170",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (8 of 9).jpg",
                        "scale": "Detail",
                        "height": 535,
                        "width": 800
                    },
                    {
                        "$id": "171",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (8 of 9).jpg?width=120&height=90&mode=max",
                        "scale": "SmallThumbnail",
                        "height": 90,
                        "width": 120
                    },
                    {
                        "$id": "172",
                        "url": "https://v12.instantsoftware.com/CustomerData/0865\\Images\\434\\405 (8 of 9).jpg?width=360&height=270&mode=max",
                        "scale": "LargeThumbnail",
                        "height": 270,
                        "width": 360
                    }
                ],
                "id": 0,
                "pmcid": null,
                "nativePMSID": "83"
            }
        ],
        "occupied": false,
        "proximity": null,
        "proximityUnitOfMeasure": "Miles",
        "vacantUntil": null,
        "occupiedUntil": null,
        "nextArrival": null,
        "phones": [],
        "maintenanceNotes": "",
        "unitVacancyStatus": null,
        "unitHousekeepingStatusType": {
            "$id": "173",
            "name": "Clean",
            "id": 1,
            "pmcid": "0865",
            "nativePMSID": "1"
        },
        "instantArea": "",
        "instantType": "Condominium",
        "instantLocation": "",
        "location": {
            "$id": "174",
            "name": null,
            "locationCategories": null,
            "id": 0,
            "pmcid": null,
            "nativePMSID": null
        },
        "option1": null,
        "option2": null,
        "option3": null,
        "additionalProperties": [],
        "isActive": true,
        "housekeepingNotes": "",
        "totalComplimentaryNights": 0,
        "availableComplimentaryNights": 0,
        "id": 0,
        "pmcid": "0865",
        "nativePMSID": "434"
    }
]
  