import React from 'react';
import { stockData,getUnits } from "./data";

export const Stocks = () => {
  return (
    <>
      <div className="stockblock">
        {stockData.map((data, key) => {
          return (
            <div key={key}>
              {data.company +
                " , " +
                data.ticker +
                " ," +
                data.stockPrice +
                ", " +
                data.timeElapsed}
            </div>
          );
        })}
      </div>


      <div className="stockblock">
        {getUnits.map((data, key) => {
          return (
            <div key={key}>
              {data.unitName +
                " , " +
                data.unitCode +
                " ," +
                data.unitComplex+
                " ," +
                data.latitude+
                " ," +
                data.longitude+
                " ," +
                data.webDescription+
                " ," +
                data.address.street1
               
             }
            </div>
          );
        })}
      </div>

    </>
  );
};